import React, { useState } from 'react';
import './CreateTest.css';

const CreateTest = () => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [showQuestionCount, setShowQuestionCount] = useState(true);

    // Hazır soru kalıpları
    const questionTemplates = [
        "Doğum tarihim ?",
        "İlk nerede tanıştık?",
        "En sevdiğim renk ?",
        "En sevdiğim film ?",
        "En sevdiğim dizi ?",
        "En sevdiğim kitap ?",
        "En sevdiğim şarkı ?",
        "Sevgili olduğumuz tarih ?",
        "Evlilik yıldönümümüz ?",
        "En sevdiğim tatil yeri ?",
        "Sana aldığım ilk hediye ?",
        "Bana aldığın ilk hediye ?",
        "İlk fotoğraf çekindiğimiz yer ?",
        "En büyük korkum ?",
        "En sevdiğim hobim ?",
        "En sevdiğim tatlı ?",
        "En sevdiğim araç markası ?",
        "En sevdiğim eşyam ?",
        "En sevdiğim yemek ?",
        "En sevdiğim sanatçı ?",
        "En sevdiğim meyve?",
        "En sevdiğim çiçek ?",
        "En sevdiğim meyve ?",
        "En sevdiğim hayvan ?",

    ];

    const startTest = (count) => {
        const newQuestions = Array.from({ length: count }, () => ({
            question: '',
            options: ['', ''],
            correctAnswer: 0
        }));
        setQuestions(newQuestions);
        setShowQuestionCount(false);
    };

    const handleQuestionChange = (value) => {
        const newQuestions = [...questions];
        newQuestions[currentQuestion].question = value;
        setQuestions(newQuestions);
    };

    const handleOptionChange = (oIndex, value) => {
        const newQuestions = [...questions];
        newQuestions[currentQuestion].options[oIndex] = value;
        setQuestions(newQuestions);
    };

    const handleCorrectAnswerChange = (value) => {
        const newQuestions = [...questions];
        newQuestions[currentQuestion].correctAnswer = value;
        setQuestions(newQuestions);
    };

    const addOption = () => {
        const newQuestions = [...questions];
        if (newQuestions[currentQuestion].options.length < 3) {
            newQuestions[currentQuestion].options.push('');
            setQuestions(newQuestions);
        }
    };

    const nextQuestion = () => {
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        }
    };

    const prevQuestion = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion(currentQuestion - 1);
        }
    };

    const isLastQuestion = currentQuestion === questions.length - 1;

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted');

        const form = e.target;
        const formData = new FormData(form);
        formData.append('question_count', questions.length);

        questions.forEach((q, index) => {
            formData.append(`question_${index}`, q.question);
            q.options.forEach((opt, optIndex) => {
                formData.append(`option_${index}_${optIndex}`, opt);
            });
            formData.append(`correct_answer_${index}`, q.correctAnswer);
        });

        const urlEncodedData = new URLSearchParams(formData).toString();

        form.action = 'https://hadihatirla.com/create_test.php';
        form.method = 'POST';
        form.enctype = 'application/x-www-form-urlencoded';

        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = 'formData';
        hiddenField.value = urlEncodedData;
        form.appendChild(hiddenField);

        form.submit();
    };

    const applyTemplate = (template) => {
        const newQuestions = [...questions];
        newQuestions[currentQuestion].question = template;
        setQuestions(newQuestions);
    };

    if (showQuestionCount) {
        return (
            <div className="create-test-container">
                <h2>Testini Oluştur</h2>
                <div className="question-count-selection">
                    <p>Kaç soru eklemek istersiniz?</p>
                    {[1, 2, 3, 4, 5].map((count) => (
                        <button 
                            key={count} 
                            onClick={() => startTest(count)}
                            className="question-count-button"
                        >
                            {count}
                        </button>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div className="create-test-container">
            <h2>Testini Oluştur</h2>
            <form onSubmit={handleSubmit}>
                <div className="question-container">
                    <div className="question-number">{currentQuestion + 1}</div>
                    <input
                        type="text"
                        name={`question_${currentQuestion}`}
                        placeholder={`Soru ${currentQuestion + 1}`}
                        value={questions[currentQuestion].question}
                        onChange={(e) => handleQuestionChange(e.target.value)}
                        className="question-input"
                        required
                    />
                    <div className="template-container">
                        <select onChange={(e) => applyTemplate(e.target.value)}>
                            <option value="">Hazır soru seç</option>
                            {questionTemplates.map((template, index) => (
                                <option key={index} value={template}>{template}</option>
                            ))}
                        </select>
                    </div>
                    {questions[currentQuestion].options.map((option, oIndex) => (
                        <div key={oIndex} className="option-container">
                            <input
                                type="text"
                                name={`option_${currentQuestion}_${oIndex}`}
                                placeholder={`Cevap ${oIndex + 1}`}
                                value={option}
                                onChange={(e) => handleOptionChange(oIndex, e.target.value)}
                                className="option-input"
                                required
                            />
                            <div className="radio-container">
                                <input
                                    type="radio"
                                    name={`correct_answer_${currentQuestion}`}
                                    value={oIndex}
                                    checked={questions[currentQuestion].correctAnswer === oIndex}
                                    onChange={() => handleCorrectAnswerChange(oIndex)}
                                    required
                                />
                                <label>Doğru Cevap</label>
                            </div>
                        </div>
                    ))}
                    {questions[currentQuestion].options.length < 3 && (
                        <button type="button" onClick={addOption} className="add-option-button">
                            Ek Cevap Ekle
                        </button>
                    )}
                </div>
                <div className="navigation-buttons">
                    <button type="button" onClick={prevQuestion} disabled={currentQuestion === 0}>Önceki Soru</button>
                    <button type="button" onClick={nextQuestion} disabled={isLastQuestion || questions[currentQuestion].question === ''}>Sonraki Soru</button>
                </div>
                {isLastQuestion && (
                    <div className="finish-button-container">
                        <button type="submit" className="finish-button">Testi Bitir ve Link Oluştur</button>
                    </div>
                )}
            </form>
        </div>
    );
};

export default CreateTest;