import React from 'react';
import { useNavigate } from 'react-router-dom';
import './home.css'; // CSS dosyasını import et

const Home = () => {
    const navigate = useNavigate();

    const handleCreateTest = () => {
        navigate('/create-test');
    };

    return (
        <div>
            <img src="logo.png" alt="Logo" className="logo" /> {/* Logo */}
            <div className="button-text">İster kendi hazırlayacağın sorularla, ister hazır soru kalıplarıyla, Anılarını Test Et!</div> {/* Butonun üstündeki yazı */}
            <button onClick={handleCreateTest}>
                Testi Oluştur!
            </button>
        </div>
    );
};

export default Home;
